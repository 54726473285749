.container {  
    max-width: auto;   
       
  }
.imgcontainer{     
    margin: auto;
    display: block;  
    width: 400px;    
    /* max-height: 150;
    max-width: 100%; */
}
.downloadapk{     
    margin: auto;
    display: block;  
    width: 50px;    
    /* max-height: 150;
    max-width: 100%; */
}
.itemlistyle {
    max-width: 350px;
    margin: auto;     
}
.tabcolor{
    --background: #067425;
    --color: #ffffff
}
.modalfit{
    width: auto;
    height: auto;
}
.cart-badge {
    position: absolute;
    top: 0px;
    right: 0px;
 }

 .maingrid{   
    position: absolute;
    left: 0;
    right: 0;
    top: 20%;
}
